@mixin font-size-default($size: 14) {
    $lineHeight: $size + 4;
    font-size: $size + px;
    line-height: $lineHeight + px;
}

@mixin mobile-block() {
    @media screen and (max-width: 768px) {
        width: 100%;
    }
}

@mixin multi-line ($num) {
    word-break: break-all;
    display: -webkit-box;
    -webkit-line-clamp: $num;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

@mixin single-line {
    overflow: hidden;
    text-overflow: ellipsis;
    -o-text-overflow: ellipsis;
    -webkit-text-overflow: ellipsis;
    -moz-text-overflow: ellipsis;
    white-space: nowrap;
}
@mixin button-base() {
    background-color: #666666;
    color: #fff;
    cursor: pointer;
    user-select: none;

    &:active {
        transform: translateY(1px);
    }
}
.container {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: var(--text-second-color);
   

    .description {
        margin-bottom: 16px;
        font-size: 20px;
    }

    .link {
        font-size: 16px;
    }
}